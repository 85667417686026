














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { BookingState } from '../../api/bookings/booking-state.enum';
import { Booking } from '../../api/bookings/booking.model';
import { PaymentStatus } from '../../api/payments/payment-status.enum';
import { Payment } from '../../api/payments/payment.model';
import { printDuration } from '../../shared/util/date.util';
import moment from 'moment-timezone';
import GeneralInformationListItem from '../GeneralInformationListItem.vue';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { GeneralInformationListItem },
})
export default class GeneralInformation extends Vue {
  colorMapping = {
    [BookingState.Pending]: 'primary',
    [BookingState.Started]: 'success',
    [BookingState.Ended]: 'danger',
    [BookingState.Cancelled]: 'secondary',
    [BookingState.Expired]: 'warning',
  };

  @Prop()
  booking!: Booking;

  @Prop()
  payments!: Payment[];

  get state() {
    return this.booking?.state;
  }

  get stateVariant() {
    return this.colorMapping[this.state];
  }

  get duration() {
    return printDuration(this.booking.start, this.booking.end);
  }

  get price() {
    return this.booking.calculatedPrice?.toFixed(2);
  }

  get fine() {
    return this.booking.fine?.toFixed(2);
  }

  get paymentUrl() {
    return `https://www.mollie.com/dashboard/payments/${this.payments[0].externalId}`;
  }

  get paymentId() {
    return this.payments[0].externalId;
  }

  get user() {
    if (this.booking?.user) {
      return `${this.booking?.user?.firstName} ${this.booking?.user?.lastName}`;
    }
    return 'N/A';
  }

  get userId() {
    return this.booking?.user?._id;
  }

  get asset() {
    return this.booking?.asset?.name || 'N/A';
  }

  get assetId() {
    return this.booking?.asset?._id;
  }

  get createdAt() {
    return moment(this.booking?.createdAt)
      .tz(this.booking?.asset?.timezone)
      .format('ddd, DD-MM-YYYY HH:mm (Z)');
  }

  get updatedAt() {
    return moment(this.booking?.updatedAt)
      .tz(this.booking?.asset?.timezone)
      .format('ddd, DD-MM-YYYY HH:mm (Z)');
  }

  get hasPayment() {
    return (
      this.payments.length > 0 &&
      this.payments[0].status !== PaymentStatus.notCreated
    );
  }

  openPayment() {
    window.alert('OPEN PAY');
  }
}
