import { BaseModel } from '@/shared/types/base.model';
import { File } from '@/shared/types/file.model';
import { BookingExportFilter } from './booking-export-filter.model';

export class BookingExport extends BaseModel {
  constructor(
    public filter: BookingExportFilter = new BookingExportFilter(),
    public email?: string,
    public file?: File,
    public user?: string,
  ) {
    super();
  }
}
