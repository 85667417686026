



























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import moment from 'moment-timezone';

import ProjectSelect from '@/components/ProjectSelect.vue';
import { SaltoSite } from '@/api/salto-sites/salto-site.model';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { ProjectSelect },
})
export default class SaltoSiteInformation extends Vue {
  @Prop()
  saltoSite!: SaltoSite;

  get siteName() {
    return this.saltoSite?.customer_reference || 'N/A';
  }

  get siteId() {
    return this.saltoSite?.id || 'N/A';
  }

  get createdAt() {
    return moment
      .utc(this.saltoSite?.createdAt)
      .format('ddd, DD-MM-YYYY HH:mm (Z)');
  }

  get updatedAt() {
    return moment
      .utc(this.saltoSite?.updatedAt)
      .format('ddd, DD-MM-YYYY HH:mm (Z)');
  }
}
