























import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';

import BaseForm from '@/components/BaseForm.vue';
import BaseTable from '@/components/BaseTable.vue';
import BaseHeader from '@/components/BaseHeader.vue';

import { Column } from '@/shared/types/column.class';
import { CastType, Filter, Operator } from '@/shared/types/filter.class';

import { SaltoLock } from '@/api/salto-locks/salto-lock.model';
import { Prop, Watch } from 'vue-property-decorator';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const saltolocksModule = namespace('saltoLocks');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTable, BaseHeader, BaseForm } })
export default class SaltoLocksTable extends Vue {
  @Prop({
    required: true,
  })
  site!: string;

  showFilters = false;
  isLoading = false;
  filter: Filter = new Filter();

  columns: Column[] = [
    new Column('customer_reference', 'Name'),
    new Column('mac', 'MAC'),
  ];

  @saltolocksModule.Getter('all')
  allSaltoLocks!: SaltoLock[];

  @saltolocksModule.Action('fetchAll')
  fetchAllSaltoLocks!: (filter?: Filter) => Promise<SaltoLock[]>;

  @saltolocksModule.Getter('all')
  saltolocks!: SaltoLock[];

  @saltolocksModule.Getter('pagination')
  pagination!: PaginateResult<SaltoLock>;

  @saltolocksModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<SaltoLock[]>;

  @saltolocksModule.Action('update')
  update!: (lock: SaltoLock) => Promise<SaltoLock>;

  @saltolocksModule.Action('activateAllForSite')
  activateAllForSite!: ({ site: string, state: boolean }) => Promise<void>;

  @Watch('site')
  onSiteChanged() {
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;
    this.filter.replaceFilter(
      'site',
      this.site,
      Operator.Equal,
      CastType.ObjectId,
    );
    await this.fetchAll(this.filter);
    this.isLoading = false;
  }

  doSearch(searchString: string) {
    this.filter.setSearch(searchString);
    this.doFetchAll();
  }

  doSort(field: string) {
    this.filter.setSort(field);
    this.doFetchAll();
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAll();
  }

  async toggleLock(payload: { state: boolean; element: SaltoLock }) {
    this.isLoading = true;
    const lock = payload.element;
    lock.active = payload.state;
    await this.update(lock);
    this.isLoading = false;
  }

  async toggleAllLocks(state: boolean) {
    this.isLoading = true;
    await this.activateAllForSite({ site: this.site, state });
    this.doFetchAll();
    this.isLoading = false;
  }

  async created() {
    this.doFetchAll();
  }
}
