export class PaginateResult<T> {
  hasNextPage = false;
  hasPrevPage = false;
  limit = 25;
  nextPage = 1;
  page = 1;
  pagingCounter = 1;
  prevPage = 1;
  totalDocs = 0;
  totalPages = 1;
  docs: T[] = [];
}
