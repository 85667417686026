




















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit, Watch } from 'vue-property-decorator';
import {
  FormElement,
  FormElementType,
  FormElementOptions,
} from '../shared/types/form-element.class';
import { Booking } from '../api/bookings/booking.model';
import { namespace } from 'vuex-class';
import { Filter, FilterOperation } from '../shared/types/filter.class';

import BaseHeader from '../components/BaseHeader.vue';
import BaseTable, { BaseTableTab } from '../components/BaseTable.vue';
import LogsTable from '../components/LogsTable.vue';
import GeneralInformation from '../components/BookingDetail/GeneralInformation.vue';
import NotFound from '../components/NotFound.vue';
import BookingDatePicker from '../components/BookingDetail/BookingDatePicker.vue';
import { Column } from '../shared/types/column.class';
import { BookingState } from '../api/bookings/booking-state.enum';
import Toast from '../shared/types/toast.class';
import { ToastType } from '../shared/types/toast-type.enum';
import { Payment } from '../api/payments/payment.model';

const bookingsModule = namespace('bookings');
const paymentsModule = namespace('payments');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseHeader,
    BaseTable,
    GeneralInformation,
    BookingDatePicker,
    NotFound,
    LogsTable,
  },
})
export default class BookingDetail extends Vue {
  logs = [];
  logColumns = [new Column('_id', 'ID'), new Column('createdAt', 'Created At')];
  logTabs = [
    new BaseTableTab('all', 'All'),
    new BaseTableTab('keywi', 'Keywi Commands'),
    new BaseTableTab('update', 'Updates'),
  ];
  currentLogTab = 'all';

  @bookingsModule.Getter('current')
  booking!: Booking;

  @paymentsModule.Getter('all')
  payments!: Payment;

  @bookingsModule.Action('fetchById')
  fetchById!: (id: string) => Promise<Booking>;

  @bookingsModule.Action('delete')
  delete!: (id?: string) => Promise<void>;

  @bookingsModule.Action('create')
  create!: (booking: Booking) => Promise<Booking>;

  @bookingsModule.Action('update')
  update!: (booking: Booking) => Promise<Booking>;

  @bookingsModule.Action('cancel')
  cancelBooking!: (id: string) => Promise<Booking>;

  @bookingsModule.Action('start')
  startBooking!: (id: string) => Promise<Booking>;

  @bookingsModule.Action('stop')
  stopBooking!: (id: string) => Promise<Booking>;

  @paymentsModule.Action('fetchAll')
  fetchPayments!: (filter: Filter) => Promise<Payment[]>;

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  get notFound() {
    return !this.isLoading('app') && !this.booking;
  }

  get timezone() {
    return this.booking?.asset?.timezone;
  }

  get nextActionVariant() {
    if (this.canStart) {
      return 'success';
    }

    if (this.canCancel) {
      return 'warning';
    }

    return 'danger';
  }

  get nextAction() {
    if (this.canStart) {
      return 'Start';
    }
    if (this.canStop) {
      return 'Stop';
    }

    if (this.canCancel) {
      return 'Cancel';
    }

    return 'Delete';
  }

  get nextActionIcon() {
    if (this.canStart) {
      return 'play-circle';
    }
    if (this.canStop) {
      return 'stop-circle';
    }

    if (this.canCancel) {
      return 'x-circle';
    }

    return 'trash-2';
  }

  get canStop() {
    return this.booking.state === BookingState.Started;
  }

  get canStart() {
    return this.booking.state === BookingState.Pending;
  }

  get canCancel() {
    return this.booking.state === BookingState.Pending;
  }

  onLogsTabChanged(tab: string) {
    this.currentLogTab = tab;
  }

  doNextAction() {
    if (this.nextAction === 'Stop') {
      return this.doStop();
    }
    if (this.nextAction === 'Start') {
      return this.doStart();
    }
    if (this.nextAction === 'Cancel') {
      return this.doCancel();
    }
    if (this.nextAction === 'Delete') {
      return this.doDelete();
    }
  }

  async doSave() {
    this.addLoader('app');
    const toast = new Toast('Booking Updated', ToastType.SUCCESS);
    try {
      await this.update(this.booking);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
  }

  async doCancel() {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to cancel this booking?`,
      {
        title: 'Cancel booking',
        okVariant: 'warning',
        centered: true,
      },
    );
    if (!result) return;

    this.addLoader('app');
    const toast = new Toast('Booking cancelled', ToastType.SUCCESS);
    try {
      await this.cancelBooking(this.booking._id);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
  }

  async doStart() {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to start this booking?`,
      {
        title: 'Start',
        okVariant: 'primary',
        centered: true,
      },
    );
    if (!result) return;

    this.addLoader('app');
    const toast = new Toast('Booking started', ToastType.SUCCESS);
    try {
      await this.startBooking(this.booking._id);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
  }

  async doStop() {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to stop this booking?`,
      {
        title: 'Stop',
        okVariant: 'primary',
        centered: true,
      },
    );
    if (!result) return;

    this.addLoader('app');
    const toast = new Toast('Booking stopped', ToastType.SUCCESS);
    try {
      await this.stopBooking(this.booking._id);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
  }

  async doDelete() {
    const result = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to delete this booking?`,
      {
        title: 'Delete',
        okVariant: 'danger',
        centered: true,
      },
    );
    if (!result) return;

    this.addLoader('app');
    const toast = new Toast('Booking deleted', ToastType.SUCCESS);
    try {
      await this.delete(this.booking._id);
    } catch (e) {
      toast.message = e.message;
      toast.type = ToastType.ERROR;
    }
    this.addToast(toast);
    this.removeLoader('app');
    this.$router.go(-1);
  }

  async created() {
    this.addLoader('app');
    try {
      await this.fetchById(this.$route.params.id);
      await this.fetchPayments(
        new Filter([new FilterOperation('booking', this.$route.params.id)]),
      );
    } catch (e) {
      this.addToast(new Toast(e.message, ToastType.ERROR));
    }
    this.removeLoader('app');
  }
}
