































import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import Vue from 'vue';
import Component from 'vue-class-component';
import { VModel, Prop, Watch } from 'vue-property-decorator';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {},
})
export default class BaseSelect extends Vue {
  @VModel({ type: Array })
  data!: unknown[];

  @Prop()
  title!: string;

  @Prop()
  label!: string;

  @Prop()
  multiple!: boolean;

  @Prop({
    default: false,
  })
  hideLabel!: boolean;

  @Prop()
  reduce!: () => any;

  @Prop({
    type: Object,
    default: () => new Filter(),
  })
  filter!: Filter;

  @Prop({ type: Function })
  fetchElements!: (filter?: Filter) => Promise<PaginateResult<unknown>>;

  isLoading = false;
  localFilter: Filter;
  elements: PaginateResult<unknown> = new PaginateResult<unknown>();

  @Watch('filter', { immediate: true, deep: true })
  onFilterChanged() {
    this.localFilter = this.filter.copy();
    this.doFetchElements();
  }

  async doSearch(search: string): Promise<void> {
    this.localFilter.setSearch(search);
    this.doFetchElements();
  }

  async doFetchElements(): Promise<void> {
    this.isLoading = true;
    this.elements = await this.fetchElements(this.localFilter);
    this.isLoading = false;
  }

  created() {
    this.doFetchElements();
  }
}
