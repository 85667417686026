



import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import BaseForm from './BaseForm.vue';

import { BookingExport } from '@/api/booking-exports/booking-export.model';
import {
  FormElement,
  FormElementOptions,
  FormElementType,
} from '@/shared/types/form-element.class';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
  },
})
export default class BookingExportForm extends Vue {
  @Prop()
  bookingExport!: BookingExport;

  formElements: FormElement[][] = [
    [
      new FormElement(
        'filter.from',
        'From',
        new FormElementOptions(),
        'required',
        FormElementType.DATE,
      ),
      new FormElement(
        'filter.to',
        'To',
        new FormElementOptions(),
        'required',
        FormElementType.DATE,
      ),
    ],
  ];
}
