import { BaseModel } from '@/shared/types/base.model';
import { BookingState } from './booking-state.enum';
import { User } from '../users/user.class';
import { Project } from '../projects/project.model';
import { Asset } from '../assets/asset.model';

export class Booking extends BaseModel {
  state?: BookingState = BookingState.Pending;

  constructor(
    public start?: Date,
    public end?: Date,
    public asset?: Asset,
    public startedOn?: Date,
    public stoppedOn?: Date,
    public cancelledOn?: Date,
    public invoicedOn?: Date,
    public expiresOn?: Date,
    public user?: User,
    public project?: string,
    public company?: string,
    public calculatedPrice?: number,
    public fine?: number,
    public isUTC = true,
  ) {
    super();
  }

  setToNextHour() {
    this.start = new Date();
    this.end = new Date();
    this.end.setTime(this.start.getTime() + 60 * 60 * 1000);
  }
}
