












import Vue from 'vue';
import BaseSelect from '../components/BaseSelect.vue';
import { Project } from '@/api/projects/project.model';
import ProjectsService from '@/api/projects/projects.service';
import { CastType, Filter } from '@/shared/types/filter.class';
import Component from 'vue-class-component';
import { VModel, Prop, Watch } from 'vue-property-decorator';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const projectsService = new ProjectsService();

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { BaseSelect },
})
export default class ProjectSelect extends Vue {
  @VModel({ type: Array })
  data!: Project[];

  @Prop()
  multiple!: boolean;

  @Prop({
    type: Object,
    default: () => new Filter(),
  })
  filter!: Filter;

  @Prop()
  companies!: string[];

  @Prop({
    default: false,
  })
  hideLabel!: boolean;

  @Prop({
    default: false,
  })
  useId!: boolean;

  localFilter: Filter = new Filter();

  @Watch('companies', { immediate: true, deep: true })
  onCompaniesChanged() {
    this.localFilter.removeForKey('company');
    if (this.companies && this.companies.length > 0) {
      this.localFilter.replaceFilter(
        'company',
        this.companies,
        undefined,
        CastType.ObjectId,
      );
    }
  }

  @Watch('filter', { immediate: true, deep: true })
  onFilterChanged() {
    this.localFilter = this.filter.copy();
  }

  reduce(x) {
    if (this.useId) {
      return x._id;
    }
    return x;
  }

  async fetchProjects(filter: Filter): Promise<PaginateResult<Project>> {
    return projectsService.fetch(filter);
  }
}
