



























import { SaltoSite } from '@/api/salto-sites/salto-site.model';
import { Column } from '@/shared/types/column.class';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import BaseHeader from '../../components/BaseHeader.vue';
import BaseTable from '../../components/BaseTable.vue';
import ProjectSelect from '../../components/ProjectSelect.vue';

const appModule = namespace('app');
const saltoSitesModule = namespace('saltoSites');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseHeader,
    ProjectSelect,
    BaseTable,
  },
})
export default class SaltoSites extends Vue {
  showDetail = false;
  filter: Filter = new Filter();
  columns: Column[] = [
    new Column('customer_reference', 'Site'),
    new Column('owner', 'Owner', this.formatOwner),
    new Column('project.name', 'Project'),
  ];

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => boolean;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => boolean;

  @saltoSitesModule.Getter('pagination')
  pagination!: PaginateResult<SaltoSite>;

  @saltoSitesModule.Action('fetchAll')
  fetchSites!: (filter?: Filter) => Promise<SaltoSite[]>;

  @saltoSitesModule.Action('syncSites')
  syncSites!: () => Promise<void>;

  formatOwner(column: Column, obj: SaltoSite) {
    return `${obj.owner.first_name} ${obj.owner.last_name} (${obj.owner.email})`;
  }

  openDetail(site: SaltoSite) {
    this.$router.push({ name: 'SaltoSite', params: { id: site._id } });
  }

  doSearch(searchString: string) {
    this.filter.setSearch(searchString);
    this.doFetchAll();
  }

  doSort(field: string) {
    this.filter.setSort(field);
    this.doFetchAll();
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAll();
  }

  async doFetchAll() {
    this.addLoader('salto-sites');
    await this.fetchSites(this.filter);
    this.removeLoader('salto-sites');
  }

  async doSave() {
    console.log('');
  }

  async doSync() {
    this.addLoader('sync');
    await this.syncSites();
    this.removeLoader('sync');
    this.doFetchAll();
  }

  created() {
    this.doFetchAll();
  }
}
