















































import Vue from 'vue';
import { SaltoSite } from '@/api/salto-sites/salto-site.model';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import BaseHeader from '../../components/BaseHeader.vue';
import SaltoSiteInformation from '../../components/salto/SaltoSiteInformation.vue';
import SaltoIqsTable from '../../components/salto/SaltoIqsTable.vue';
import SaltoLocksTable from '../../components/salto/SaltoLocksTable.vue';

const appModule = namespace('app');
const saltoSitesModule = namespace('saltoSites');
const saltoIqsModule = namespace('saltoIqs');
const saltoLocksModule = namespace('saltoLocks');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseHeader,
    SaltoSiteInformation,
    SaltoIqsTable,
    SaltoLocksTable,
  },
})
export default class SaltoSiteDetail extends Vue {
  detailTabs = ['Iqs', 'Locks'];
  detailTab = 'Iqs';

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => boolean;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => boolean;

  @saltoSitesModule.Getter('current')
  saltoSite!: SaltoSite;

  @saltoSitesModule.Action('fetchById')
  fetchById!: (id: string) => Promise<SaltoSite>;

  @saltoIqsModule.Action('syncIqs')
  syncIqs!: (site: string) => Promise<void>;

  @saltoLocksModule.Action('syncLocks')
  syncLocks!: (site: string) => Promise<void>;

  @saltoSitesModule.Action('update')
  update!: (site: SaltoSite) => Promise<SaltoSite>;

  get saltoSiteId() {
    return this.$route.params.id;
  }

  get siteName() {
    return this.saltoSite?.customer_reference;
  }

  owner() {
    return `${this.saltoSite.owner.first_name} ${this.saltoSite.owner.last_name} (${this.saltoSite.owner.email})`;
  }

  async doFetchSite() {
    this.addLoader(this.saltoSiteId);
    await this.fetchById(this.saltoSiteId);
    this.removeLoader(this.saltoSiteId);
  }

  async doSave() {
    this.addLoader(this.saltoSiteId);
    await this.update(this.saltoSite);
    this.removeLoader(this.saltoSiteId);
  }

  async doSync() {
    this.addLoader('sync');
    await this.syncIqs(this.saltoSiteId);
    await this.syncLocks(this.saltoSiteId);
    this.removeLoader('sync');
  }

  created() {
    this.doFetchSite();
  }
}
