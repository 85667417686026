




































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';

import BaseForm from '@/components/BaseForm.vue';
import BaseTable from '@/components/BaseTable.vue';
import BaseHeader from '@/components/BaseHeader.vue';

import { Column } from '@/shared/types/column.class';
import { CastType, Filter, Operator } from '@/shared/types/filter.class';

import { SaltoIq } from '@/api/salto-iqs/salto-iq.model';
import { Prop, Watch } from 'vue-property-decorator';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const saltoiqsModule = namespace('saltoIqs');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTable, BaseHeader, BaseForm } })
export default class SaltoIqsTable extends Vue {
  @Prop({
    required: true,
  })
  site!: string;

  iqToActivate: SaltoIq;
  activationPin = '';
  activatingWithPin = false;
  showPinModal = false;
  showFilters = false;
  isLoading = false;
  isLoadingModal = false;
  filter: Filter = new Filter();

  columns: Column[] = [
    new Column('customer_reference', 'Name'),
    new Column('mac', 'MAC'),
  ];

  @saltoiqsModule.Getter('all')
  saltoiqs!: SaltoIq[];

  @saltoiqsModule.Getter('pagination')
  pagination!: PaginateResult<SaltoIq>;

  @saltoiqsModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<SaltoIq[]>;

  @saltoiqsModule.Action('activate')
  activate!: (id: string) => Promise<SaltoIq | boolean>;

  @saltoiqsModule.Action('deactivate')
  deactivate!: (id: string) => Promise<SaltoIq>;

  @saltoiqsModule.Action('activateWithPin')
  activateWithPin!: (payload: { iq: string; pin: string }) => Promise<SaltoIq>;

  @Watch('site')
  onSiteChanged() {
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;
    this.filter.replaceFilter(
      'site',
      this.site,
      Operator.Equal,
      CastType.ObjectId,
    );
    await this.fetchAll(this.filter);
    this.isLoading = false;
  }

  doSearch(searchString: string) {
    this.filter.setSearch(searchString);
    this.doFetchAll();
  }

  doSort(field: string) {
    this.filter.setSort(field);
    this.doFetchAll();
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAll();
  }

  async doActivate(iq: SaltoIq) {
    const wasUpdated = await this.activate(iq._id);
    if (!wasUpdated) {
      this.requestPin(iq);
    }
  }

  async requestPin(iq: SaltoIq) {
    this.iqToActivate = iq;
    this.showPinModal = true;
  }

  async doActivateWithPin() {
    this.activatingWithPin = true;

    await this.activateWithPin({
      iq: this.iqToActivate._id,
      pin: this.activationPin,
    });
    this.clearActivateWithPin();
  }

  clearActivateWithPin() {
    this.activationPin = '';
    this.iqToActivate = null;
    this.activatingWithPin = false;
    this.showPinModal = false;
  }

  async toggleIq(payload: { state: boolean; element: SaltoIq }) {
    this.isLoading = true;
    const iq = payload.element;
    if (payload.state) {
      await this.doActivate(iq);
    } else {
      await this.deactivate(iq._id);
    }
    this.isLoading = false;
  }

  async created() {
    this.doFetchAll();
  }
}
