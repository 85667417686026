



















































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';

import BookingsTable from '../components/BookingsTable.vue';
import BaseHeader from '../components/BaseHeader.vue';
import BookingForm from '../components/BookingForm.vue';
import BookingExportForm from '../components/BookingExportForm.vue';

import { Booking } from '../api/bookings/booking.model';
import { SortOrder } from '../shared/types/sort-order.enum';
import { Filter } from '../shared/types/filter.class';
import { Role } from '@/api/auth/role.enum';
import { User } from '@/api/users/user.class';
import { BookingExport } from '@/api/booking-exports/booking-export.model';
import moment from 'moment';

const bookingsModule = namespace('bookings');
const bookingExportsModule = namespace('bookingExports');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { BookingsTable, BaseHeader, BookingForm, BookingExportForm },
})
export default class Bookings extends Vue {
  $refs!: Vue['$refs'] & {
    createModal: BModal;
  };

  newExportData = new BookingExport();

  showExportModal = false;
  filter = new Filter([], { start: SortOrder.Asc });
  newBooking: Booking = new Booking();

  @usersModule.Getter('loggedInUser')
  loggedInUser!: User;

  @bookingsModule.Action('create')
  create!: (booking: Booking) => Promise<Booking>;

  @bookingExportsModule.Action('create')
  createExport!: (bookingExport: BookingExport) => Promise<BookingExport>;

  get isProjectAdmin() {
    return this.$can('is', Role.ProjectAdmin);
  }

  get projectId() {
    return this.loggedInUser.project?._id;
  }

  get calendarLink() {
    return {
      name: 'ProjectCalendar',
      params: {
        id: this.projectId,
      },
    };
  }

  async doCreateExport(bookingExport: BookingExport) {
    await this.createExport(bookingExport);
  }

  openCreateModal() {
    this.newBooking.setToNextHour();
    this.$refs.createModal.show();
  }

  openExportModal() {
    this.newExportData = new BookingExport();
    this.newExportData.filter.to = moment()
      .endOf('day')
      .toDate();
    this.newExportData.filter.from = moment()
      .startOf('month')
      .toDate();
    this.showExportModal = true;
  }
}
